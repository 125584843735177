<template>
  <div class="tw-mb-5">
    <router-view :user="user" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
let floating = document.getElementById("onetrust-consent-sdk");

export default {
  name: "ProfileView",
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  methods: {
    ...mapActions(["meta/showGlobalAlert"]),
    async save() {
      await this.$store.dispatch("users/patch", [this.user.id, this.user]);
    },

    async logout() {
      await this.$store.dispatch("auth/logout");
      location.reload();
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Profile",
      bgColor: "#6A4AE8",
      fontColor: "#FFFFFF",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    if (floating) floating.style.display = "block";
  },
  destroyed() {
    if (floating) floating.style.display = "none";
  }
};
</script>

<style lang="scss"></style>
